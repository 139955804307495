import { isEmpty } from "lodash";

import { NodeParameter } from "../../types";

export const validateParameter = (param: NodeParameter) => {
  const errors: string[] = [];
  const emptyError = param.isMandatory && isEmpty(param.value?.trim());

  if (emptyError) {
    errors.push(`${param.name} cannot be empty`);
    return { isInValid: true, errors };
  }
  return {
    isInValid: false,
    message: "",
  };
};

export const combineParameters = (
  updatedParams: NodeParameter[],
  existingParams: NodeParameter[]
) => {
  return existingParams.map((param) => {
    const updatedParam = updatedParams.find((p) => p.name === param.name);
    return updatedParam ? updatedParam : param;
  });
};

export const getValidatedParams = (parameters: NodeParameter[]) => {
  let hasErrors = false;
  if (isEmpty(parameters)) {
    return { updatedParamList: [], hasErrors };
  }
  const updatedParamList = parameters.map((param) => {
    const validationRes = validateParameter(param);
    hasErrors = validationRes.isInValid || hasErrors;
    return {
      ...param,
      errors: validationRes.errors ?? [],
      value: param?.value?.trim(),
    };
  });
  return { updatedParamList, hasErrors };
};
