import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Instance, Node } from "reactflow";

import { useSaveWorkflowMutation } from "@/features/workflow-studio";
import { useFlowLocalSave } from "@/features/workflow-studio/hooks/useSaveToLocal.ts";
import {
  AUTO_SAVE_DEBOUNCE,
  updateNodesAfterSave,
} from "@/features/workflow-studio/utils";
import { ConvertFlowtoWorkflowPayload } from "@/features/workflow-studio/utils/transform-response.ts";
import {
  useSingletonDebounce
} from "@/hooks/useSingletonDebounce.ts";
import { useAppSelector } from "@/reduxHooks.ts";

import {
  getEditingAllowed,
  isAutoSaving,
  resetTriggerAutoSave,
  setIsSaving,
  setLastSavedTime,
  shouldTriggerAutoSave,
  timerInterval,
} from "../redux";

interface UseFlowAutoSaveHookReturn {
  isSaving: boolean;
  cancelAutoSave: () => void;
}

/**
 * Custom hook to auto-save the flow instance at a given interval
 * @param toObject - The Flow instance to save
 * @param setNodes - The interval (in seconds) at which to save the flow instance
 */
export const useFlowAutoSave = (
  toObject: Instance.ToObject,
  setNodes: (
    value: React.SetStateAction<Node<any, string | undefined>[]>
  ) => void
): UseFlowAutoSaveHookReturn => {
  const dispatch = useDispatch();
  const params = useParams();
  const instance = toObject();
  const interval = useAppSelector(timerInterval);
  const isSaving = useAppSelector(isAutoSaving);
  const triggerAutoSave = useAppSelector(shouldTriggerAutoSave);
  const isEditingAllowed = useAppSelector(getEditingAllowed);
  
  // Queue to store pending save operations
  const saveQueueRef = useRef<(() => Promise<void>)[]>([]);
  // Flag to track if a save is in progress
  const isSaveInProgressRef = useRef(false);

  const [saveWorkflow] = useSaveWorkflowMutation();
  const intervalRef = useRef<number | null>(null);
  const [shouldRunInterval, setShouldRunInterval] = useState(true);

  const { editorId } = useParams();
  const { triggerLocalSave } = useFlowLocalSave(instance, editorId!);

  // Function to process the save queue
  const processSaveQueue = useCallback(async () => {
    if (isSaveInProgressRef.current || saveQueueRef.current.length === 0) return;

    isSaveInProgressRef.current = true;
    const nextSave = saveQueueRef.current[0];

    try {
      await nextSave();
      // Remove the completed save operation
      saveQueueRef.current.shift();
    } catch (error) {
      console.error("Error in save operation:", error);
    } finally {
      isSaveInProgressRef.current = false;
      // Process next save if any
      if (saveQueueRef.current.length > 0) {
        processSaveQueue();
      }
    }
  }, []);

  const saveWorkflowApi = async () => {
    try {
      if (!isEditingAllowed) return;
      const res = await saveWorkflow({
        analysisId: params.analysisId!,
        workflow: ConvertFlowtoWorkflowPayload(instance),
        workflowId: params.editorId!,
      }).unwrap();

      const newNodes = res.response.data!.workflows[0].workflowNodes;
      setNodes((currentNodes) => updateNodesAfterSave(newNodes, currentNodes));
      dispatch(setLastSavedTime(res.response.data!.workflows[0].lastSavedOn));
    } catch (e) {
      console.error("Error saving workflow", e);
      throw e; // Re-throw to handle in the queue processor
    }
  };

  const saveFlow = useCallback(async () => {
    dispatch(setIsSaving(true));

    const saveOperation = async () => {
      try {
        triggerLocalSave();
        await saveWorkflowApi();
      } catch (error) {
        console.error("Autosave error:", error);
      } finally {
        dispatch(setIsSaving(false));
      }
    };

    // Add save operation to queue
    saveQueueRef.current.push(saveOperation);
    // Process queue if not already processing
    processSaveQueue();
  }, [dispatch, triggerLocalSave, saveWorkflowApi, processSaveQueue]);

  const autoSave = useSingletonDebounce(
    saveFlow,
    AUTO_SAVE_DEBOUNCE,
    "AUTOSAVE_DEBOUNCE"
  );

  useEffect(() => {
    if (!editorId) return;
    if (isSaving || !instance) return;

    if (triggerAutoSave) {
      saveFlow();
      dispatch(resetTriggerAutoSave());
    }
  }, [dispatch, editorId, saveFlow, triggerAutoSave, instance, isSaving]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      // Clear any pending saves on unmount
      saveQueueRef.current = [];
    };
  }, []);

  const cancelAutoSave = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    // Clear any pending saves
    saveQueueRef.current = [];
    dispatch(setIsSaving(false));
  };

  return { isSaving, cancelAutoSave };
};
