import { Slide } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";

import { ConfigurePanel } from "@/design/components/configure-panel";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { hidePanel, selectPanel } from "../..";
import {
  resetNodeStatuesinWorkflowRunStatus,
  triggerAutoSave,
  workflowStatus,
} from "../../redux";
import { NodeParameter, NodeType } from "../../types";
import {
  NODE_STATUS,
  NODE_TYPES,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { updateUpstreamNodesStatus } from "../../utils/workflow-utils";

import { NodeConfigPanelBody } from "./config-panel-body";
import { NodeConfigPanelFooter } from "./config-panel-footer";
import { NodeConfigPanelHeader } from "./config-panel-header";
import { combineParameters, getValidatedParams } from "./config-utils";

export const NodeConfigPanel = () => {
  const nodeConfigPanel = useAppSelector(
    selectPanel(WORKFLOW_PANELS.NodeConfigurationPanel)
  );

  // const currentNodeId = useAppSelector(currentNodeConfiguration);
  const dispatch = useAppDispatch();
  const { getNode, setNodes, getNodes, getEdges } = useReactFlow();
  const [parameters, setParameters] = useState<NodeParameter[]>();
  const [currentNodeData, setCurrentNodeData] = useState<NodeType>();

  const workflowRunStatus = useAppSelector(workflowStatus);

  useEffect(() => {
    if (!nodeConfigPanel.nodeId) return;
    const node = getNode(nodeConfigPanel.nodeId);
    if (!node) return;

    const data = node.data as NodeType;
    setCurrentNodeData(data);

    const filterHiddenParams = data.parameters
      // .filter((p: NodeParameter) => !p.isHidden)
      .map((p: NodeParameter) => ({ ...p, value: p.value ?? p.defaultValue }));

    setParameters(filterHiddenParams);

    return () => {
      setCurrentNodeData(undefined);
      setParameters(undefined);
    };
  }, [nodeConfigPanel, getNode]);

  const onClose = () => {
    dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
  };

  const autoSave = () => {
    setTimeout(() => {
      dispatch(triggerAutoSave());
    }, 300);
  };

  const resetStatusOfUpstreamNodes = () => {
    if (!nodeConfigPanel.nodeId || !workflowRunStatus) return;
    const resettedNodes = updateUpstreamNodesStatus({
      startNodeId: nodeConfigPanel.nodeId,
      nodes: getNodes(),
      edges: getEdges(),
      nodesWithStatus: workflowRunStatus.workflowNodeStatus,
      status: NODE_STATUS.DEFAULT,
    });
    dispatch(resetNodeStatuesinWorkflowRunStatus(resettedNodes));
  };

  const updateNodeParams = (newParamList: NodeParameter[]) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeConfigPanel.nodeId) {
          const nodeData = node.data as NodeType;
          const updatedData = {
            ...nodeData,
            nodeStatus: NODE_STATUS.DEFAULT,
            parameters: combineParameters(newParamList, nodeData.parameters),
          };

          // Update display name for Source nodes
          if (nodeData.nodeType === NODE_TYPES.SOURCE_NODE) {
            const datasetParam = newParamList.find(
              (p) => p.name === "dataset_info"
            );
            if (!isEmpty(datasetParam?.value)) {
              try {
                const datasetInfo = JSON.parse(datasetParam?.value as string);
                updatedData.displayName = datasetInfo.displayName;
              } catch (error) {
                console.error("Failed to parse dataset_info:", error);
              }
            }
          }

          node.data = updatedData;
        }
        return node;
      })
    );
  };

  const onSave = () => {
    if (!nodeConfigPanel.nodeId) return;

    const { hasErrors, updatedParamList } = getValidatedParams(parameters!);
    setParameters(updatedParamList);

    if (hasErrors) return;
    else {
      // DESC : Get the downstream nodes from the current node and reset the status of those nodes
      // const upstreamNodeIds = traverseDownstreamNodes(
      //   nodeConfigPanel.nodeId,
      //   getNodes(),
      //   getEdges()
      // );
      updateNodeParams(updatedParamList);
      /* 
     // NOTE: if workflowRunstatus is present, update the status of upstream in workflowRunStatus
     if (workflowRunStatus) {
       resetStatusOfUpstreamNodes();
     }*/
      dispatch(hidePanel(WORKFLOW_PANELS.NodeConfigurationPanel));
      autoSave();
    }
  };

  return (
    <Slide
      className="!absolute top-0 !right-0 h-full p-5 !w-fit"
      direction="right"
      in={nodeConfigPanel.isVisible}
      style={{ zIndex: 10 }}
      unmountOnExit={true}
    >
      {currentNodeData && (
        <ConfigurePanel className="max-h-full">
          <NodeConfigPanelHeader
            onClose={onClose}
            title={currentNodeData.displayName}
          />
          <NodeConfigPanelBody
            node={currentNodeData}
            parameters={parameters!}
            setParameters={setParameters}
          />
          <NodeConfigPanelFooter onClose={onSave} />
        </ConfigurePanel>
      )}
    </Slide>
  );
};
