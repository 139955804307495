import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Node } from "reactflow";

import { useAppDispatch } from "@/reduxHooks";

import { useLazyGetWorkflowQuery } from "../api/workflow-api";
import { ReactFlowInstanceContext } from "../components/flow-editor";
import {
  setWorkflowRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { NodeType } from "../types";
import { WorkflowNodeSchema, WorkflowSchema } from "../types/workflow-types";
import { updateNodeParameters } from "../utils";
import { NODE_STATUS } from "../utils/constants";

export const useWorkflowActions = () => {
  const { setNodesFn } = useContext(ReactFlowInstanceContext)!;
  const dispatch = useAppDispatch();
  const params = useParams();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();

  const updateNodes = (nds: Node[], workflowNodes: WorkflowNodeSchema[]) => {
    return nds.map((node) => {
      const newNode = workflowNodes.find(
        (n: WorkflowNodeSchema) => n.workflowNodeId === node.data.workflowNodeId
      );
      if (newNode) {
        return {
          ...node,
          data: {
            ...node.data,
            displayName: newNode.displayName ?? node.data.name,
            parameters: updateNodeParameters(node, newNode.nodeParameters),
            nodeStatus: newNode.nodeStatus,
            isOutput: newNode.isOutput,
            outputName: newNode.outputName,
            outputState: newNode.outputState,
            isOutputDataAvailable: newNode.isOutputDataAvailable,
          } as NodeType,
        };
      }
      return node;
    });
  };

  const fetchAndUpdateWorkflow = async () => {
    try {
      const response = await getWorkflowDetails({
        workflowId: params.editorId!,
      }).unwrap();

      const wf = response.response.data?.workflows[0] as WorkflowSchema;
      const workflowNodes = wf.workflowNodes;

      if (!wf || !workflowNodes) {
        throw new Error("No workflow data found");
      }

      // Only update nodes if setNodesFn is provided
      if (
        setNodesFn &&
        workflowNodes.length > 0 &&
        wf.workflowStatus !== NODE_STATUS.RUNNING
      ) {
        setNodesFn((nds) => updateNodes(nds, workflowNodes));
      }

      // Update workflow status and run ID in store
      dispatch(setWorkflowRunStatus(wf.workflowStatus));
      dispatch(setWorkflowRunId(wf.workflowRunId as string));

      return {
        workflow: wf,
        workflowNodes,
      };
    } catch (error) {
      console.error("Failed to fetch workflow details:", error);
      throw error;
    }
  };

  const fetchAndUpdateWFNodesOnly = async () => {
    try {
      const response = await getWorkflowDetails({
        workflowId: params.editorId!,
      }).unwrap();

      const wf = response.response.data?.workflows[0] as WorkflowSchema;
      const workflowNodes = wf.workflowNodes;

      if (!wf || !workflowNodes || !setNodesFn) return;

      if (
        wf.workflowStatus === NODE_STATUS.RUNNING ||
        workflowNodes.length <= 0
      )
        return;

      setNodesFn((nds) => updateNodes(nds, workflowNodes));

      return {
        workflow: wf,
        workflowNodes,
      };
    } catch (error) {
      console.error("Failed to update nodes:", error);
      throw error;
    }
  };

  return {
    fetchAndUpdateWorkflow,
    fetchAndUpdateWFNodesOnly,
    updateNodes,
  };
};
