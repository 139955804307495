import { MICRO_SERVICES } from "./enums";
import { regionsUrlKeys } from "./regions";

const DEV_MODE_STRING = "development";

export const isProduction = () => {
  const hostname = window.location.hostname
    .replace(".ctnxt.clootrack.com", "")
    .split(".");

  const isDev = hostname[hostname.length - 1] === "dev";
  const isQa = hostname[hostname.length - 1] === "qa";
  if (isDev || isQa) {
    return false;
  }
  return true;
};

export const isOrgSelectionPending = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    return false;
  }

  const { hostname } = window.location;
  const parts = hostname.split(".");
  
  // Find the index of the region part (cus or uae)
  const regionIndex = parts.findIndex(part => 
    Object.values(regionsUrlKeys).includes(part)
  );
  
  // If there's no part before the region, org selection is pending
  return regionIndex <= 0;
};

const devBaseUrl = () => {
  return import.meta.env.VITE_DEV_BASE_URL;
};

export const getBaseUrl = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    return devBaseUrl();
  }

  const { protocol, hostname } = window.location;
  const parts = hostname.split(".");

  let backendHostname = "";
  const hasValidRegionAtStart = Object.values(regionsUrlKeys).includes(parts[0]);
  
  // If URL has 5 or more parts and first part is not a valid region, remove it
  // Otherwise keep the full hostname and just prepend 'backend.'
  const hostnameWithoutPrefix = parts.length >= 5 && !hasValidRegionAtStart 
    ? parts.slice(1).join(".")
    : parts.join(".");
    
  backendHostname = `backend.${hostnameWithoutPrefix}`;
  return `${protocol}//${backendHostname}`;
};

export const getMSBaseUrl = (serviceName: MICRO_SERVICES) => {
  const baseUrl = getBaseUrl();
  switch (serviceName) {
    case MICRO_SERVICES.NODE_MANAGER:
      return `${baseUrl}/node-mgmt`;
    case MICRO_SERVICES.WORKFLOW_MANAGER:
      return `${baseUrl}/workflow-mgmt`;
    case MICRO_SERVICES.WORKSPACE_MANAGER:
      return `${baseUrl}/workspace-mgmt`;
    case MICRO_SERVICES.DATA_MANAGER:
      return `${baseUrl}/data-mgmt`;
    case MICRO_SERVICES.PDS:
      return `${baseUrl}/pds-mgmt`;
    case MICRO_SERVICES.USER_MANAGER:
      return `${baseUrl}/user-mgmt`;
    case MICRO_SERVICES.AI_PLAYGROUND:
      return `${baseUrl}/playground-mgmt`;
    case MICRO_SERVICES.EDA:
      return `${baseUrl}/eda-mgmt`;
    case MICRO_SERVICES.INSIGHTS:
      return `${baseUrl}/insights-mgmt`;
    case MICRO_SERVICES.DASHBOARD:
      return `${baseUrl}/viz-mgmt`;
    default:
      return `${baseUrl}`;
  }
};

/**
 * org1.uae.ctnxt.clootrack.com
 * org2.cus.ctnxt.clootrack.com
 */
export const getRegionFromUrl = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    return regionsUrlKeys.cus; // Dev is only on cus
  }
  
  const { hostname } = window.location;
  const parts = hostname.split(".");
  
  // Check each part for a valid region (cus or uae)
  for (const part of parts) {
    if (Object.values(regionsUrlKeys).includes(part)) {
      return part;
    }
  }
  
  return ""; // No region found
};

export const isRegionSelectionPending = () => {
  const region = getRegionFromUrl();
  return region === "";
};

export const getRedirectUrl = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    console.log("localhost redirect");
    return "http://localhost:3000";
  }
  const { protocol, hostname } = window.location;
  return `${protocol}//${hostname}/`;
};

export const getAuthClientIdFromRegion = () => {
  const region = getRegionFromUrl().toUpperCase();
  const envVariableName = `VITE_AZURE_CLIENT_ID_${region}`;
  return import.meta.env[envVariableName];
};

export const getAuthTenantFromRegion = () => {
  const region = getRegionFromUrl().toUpperCase();
  const envVariableName = `VITE_AZURE_TENANT_${region}`;
  return import.meta.env[envVariableName];
};
